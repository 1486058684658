<template>
	<div id="app">
		<Header />
		<router-view></router-view>
		<Footer />
	</div>
</template>

<script>
	import Header from './components/common/Header.vue'
	import Footer from './components/common/Footer.vue'
	export default {
		name: 'app',
		components: {
			Header,
			Footer
		}
	}
</script>

<style>
	body {
		margin: 0;
		padding: 0;
	}

	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}
</style>
