<template>
	<div>
		<div class="logo">
			<div class="logo-container">
				<el-image :src="require('@/assets/logo.png')"></el-image>
			</div>
		</div>
		<Navbar />
		<el-image class="banner-image" :src="global.getImage('top.jpg')" lazy></el-image>
	</div>
</template>

<script>
	import Navbar from './Navbar.vue'
	export default {
		name: 'app',
		components: {
			Navbar
		}
	}
</script>

<style scoped="true" lang="scss">
	.logo {
		display: flex;
		justify-content: center;
		background-color: #fff;

		.logo-container {
			width: 1300px;
			display: flex;
		}
	}

	.banner-image {
		display: block !important;
	}
</style>
