import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: './',
		meta: {
			index: 0,
			title: "首页"
		},
		component: () => import('../components/Home.vue')
	},
	{
		path: "*",
		redirect: "/404"
	},
	{
		path: '/404',
		name: '404',
		meta: {
			index: -1,
			title: "页面不存在"
		},
		component: () => import('../components/404.vue')
	},
	{
		path: '/company-intro',
		name: 'company-intro',
		meta: {
			index: 1,
			title: "公司简介"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/group-intro',
		name: 'group-intro',
		meta: {
			index: 1,
			title: "团队介绍"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/history-intro',
		name: 'history-intro',
		meta: {
			index: 1,
			title: "发展历程"
		},
		component: () => import('../components/History.vue')
	},
	{
		path: '/lot-cloud',
		name: 'lot-cloud',
		meta: {
			index: 2,
			title: "物联网云平台"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/smart-park',
		name: 'smart-park',
		meta: {
			index: 2,
			title: "智慧园区"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/smart-scenic-spot',
		name: 'smart-scenic-spot',
		meta: {
			index: 2,
			title: "智慧景区"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/smart-construction-site',
		name: 'smart-construction-site',
		meta: {
			index: 2,
			title: "智慧工地"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/smart-building',
		name: 'smart-building',
		meta: {
			index: 2,
			title: "智慧楼宇"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/ticket-api',
		name: 'ticket-api',
		meta: {
			index: 3,
			title: "飞机票/火车票API"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/ticket-ota',
		name: 'ticket-ota',
		meta: {
			index: 3,
			title: "飞机票OTA运价管理"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/ticket-mgr',
		name: 'ticket-mgr',
		meta: {
			index: 3,
			title: "票务订单系统管理"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/bosi-ai-study',
		name: 'bosi-ai-study',
		meta: {
			index: 4,
			title: "博思数学AI学习系统"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/quxue-cloud-classroom',
		name: 'quxue-cloud-classroom',
		meta: {
			index: 4,
			title: "趣学云课堂系统"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/mango-english',
		name: 'mango-english',
		meta: {
			index: 4,
			title: "芒果英语学习系统"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/e-shop',
		name: 'e-shop',
		meta: {
			index: 5,
			title: "综合购物平台"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/vertical-shop',
		name: 'vertical-shop',
		meta: {
			index: 5,
			title: "垂直类购物平台"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/live',
		name: 'live',
		meta: {
			index: 5,
			title: "直播平台"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/joint-shopping-app',
		name: 'joint-shopping-app',
		meta: {
			index: 6,
			title: "拼团APP"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/travel-app',
		name: 'travel-app',
		meta: {
			index: 6,
			title: "旅游类APP"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/local-service-app',
		name: 'local-service-app',
		meta: {
			index: 6,
			title: "本地服务类小程序"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/edu-site',
		name: 'edu-site',
		meta: {
			index: 7,
			title: "教育行业网站"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/one-on-one-org-site',
		name: 'one-on-one-org-site',
		meta: {
			index: 7,
			title: "一对一机构网站"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/travel-enterprise-site',
		name: 'travel-enterprise-site',
		meta: {
			index: 7,
			title: "旅游企业网站"
		},
		component: () => import('../components/common/ContentPage.vue')
	},
	{
		path: '/contact-us',
		name: 'contact-us',
		meta: {
			index: 8,
			title: "联系我们"
		},
		component: () => import('../components/ContactUs.vue')
	},
	{
		path: '/product-detail',
		name: 'product-detail',
		meta: {
			index: 9,
			title: "产品介绍"
		},
		component: () => import('../components/ProductDetail.vue')
	},
	{
		path: '/product-list',
		name: 'product-list',
		meta: {
			index: 9,
			title: "产品列表"
		},
		component: () => import('../components/ProductList.vue')
	}
]

const router = new VueRouter({
	routes
})
router.fstLvl = ["首页", "公司介绍", "智能物联网平台", "出行大数据API", "AI智能学习平台", "电商/直播平台", "APP/小程序开发", "网站开发", "联系我们"];
router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = '湖南畅飞-' + to.meta.title
	} else {
		document.title = "湖南畅飞";
	}
	next();
})
// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}
export default router
