<template>
	<div class="nav-bar">
		<el-menu :default-active="activeIndex" class="top-nav" mode="horizontal" @select="handleSelect"
			background-color="#fff" text-color="#626262 " active-text-color="#626262">
			<template v-for="item in navData">
				<template v-if="item.subs.length>1">
					<el-submenu :index="item.index" :key="item.index" class="menu">
						<template slot="title">
							<span slot="title">{{ item.title }}</span>
						</template>
						<template v-for="subItem in item.subs">
							<el-menu-item :index="subItem.index" :key="subItem.index">
								{{ subItem.title }}
							</el-menu-item>
						</template>
					</el-submenu>
				</template>
				<template v-else>
					<el-menu-item :class="item.index=='/'?'':'menu'" :index="item.index" :key="item.index">
						<span slot="title">{{ item.title }}</span>
					</el-menu-item>
				</template>
			</template>
		</el-menu>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeIndex: '/',
				navData: []
			};
		},
		mounted() {
			var navArray = [];
			for (var item in this.$router.fstLvl) {
				var navObj = {};
				navObj.title = this.$router.fstLvl[item];
				var queryData = this.$router.options.routes.filter(function(_data) {
					return _data.meta != null && _data.meta.index == item
				});
				navObj.subs = [];
				if (queryData.length > 1) {
					navObj.index = item.toString();
					for (var i = 0; i < queryData.length; i++) {
						var obj = {
							title: queryData[i].meta.title,
							index: queryData[i].path
						};
						navObj.subs.push(obj);
					}
				} else {
					var fstLvlData = this.$router.options.routes.filter(function(_data) {
						return _data.meta != null &&_data.meta.index == item
					});
					navObj.index = fstLvlData[0].path;
				}
				navArray.push(navObj);
			}
			this.navData = navArray;
		},
		watch: {
			$route(to) {
				this.activeIndex = to.path;
			}
		},
		methods: {
			handleSelect(key, keyPath) {
				var to;
				if (keyPath.length == 1)
					to = keyPath[0];
				else
					to = keyPath[1];
				this.$router.push({
					path: to
				});
			}
		}
	}
</script>

<style lang="scss">
	.nav-bar {
		border-bottom: 3px solid red;
		background: #fff;
		text-align: center;
	}

	.top-nav {
		display: inline-block;
		border-bottom: 1px solid #fff !important;
	}

	.is-active {
		border-bottom: 0 !important;
	}

	.menu {
		border-left: 1px solid $primary-font-color-disabled;
		.el-submenu__title {
			height: 3em !important;
			line-height: 3em !important;
		}
	}

	.el-menu-item {
		width: 10em;
		height: 3em !important;
		line-height: 3em !important;
	}

	.el-menu--popup li {
		width: 100% !important;
	}

	.menu.is-active {
		background-color: $primary-red !important;
		color: #fff !important;

		.el-submenu__title {
			background-color: $primary-red !important;
			color: #fff !important;
			border-bottom: 1px solid #fff !important;
		}

		.el-submenu__icon-arrow {
			color: #fff !important;
		}
	}

	.el-menu--popup .is-active {
		background-color: $primary-red !important;
		color: #fff !important;
	}
</style>
